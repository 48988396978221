
.profileContainer {
    text-align: left;
    display: grid;
    grid-template-rows: 80% auto;
}

.subscriptionsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-top: 1px solid grey;
}

.subscriptionItem {
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
    padding: 20px;
    background: linear-gradient(275deg, rgba(238,181,198,1) 0%, rgba(193,174,223,1) 70%, rgba(160,188,228,1) 100%);
}

.subscriptionItem {
    display: grid;
    grid-template-columns: 80% auto;
}

.subscriptionItem h3 {
    color: black;
}

.subscriptionItem div {
    position: relative;
    z-index: 2;
    background-color: white;
    border-radius: 6px;
    padding: 10px;
    width: 400px;
}

.nextBill {
    color: red;
}

.subscriptionsContainer button {
    border: none;
    background-color: rgba(160,188,228,1);
    padding: 10px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    max-height: 50px;
    max-width: 300px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.subscriptionItem .deco {
    position: absolute;
    color: white;
    opacity: 0.2;
    z-index: 1;
    font-size: 90px;
    margin: 0;
}

@media screen and (max-width: 430px) {
    .subscriptionItem {
        width: 85vw;
        display: flex;
        flex-direction: column;
    }

    .subscriptionItem div {
        width: 95%;
    }

    .subscriptionItem button {
        left: 50%;
        transform: translateX(-50%);
    }

    .profileContainer {
        padding: 10px;
    }
}