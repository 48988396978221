@font-face {
    font-family: 'Jura';
    src:
         url('../Fonts/JuraBook.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.dashboard {
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.dashContainer {
    display: flex;
}

.tabs {
    position: relative;
    width: 80%;
    margin-top: 20px;
    padding-inline: 100px;
    overflow: hidden;
}

.Nav {
    position: relative;
    height: 100vh;
    width: 200px;
    background-color: #FAFAFA;
}

.Nav h2 {
    font-family: 'Jura';
    font-weight: bold;
    text-transform: uppercase;
    font-size: 36px;
}

.sideBtns {
    width: 100%;
    height: 20%;
    text-align: left;
    display: flex;
    top: 25%;
    position: absolute;
    flex-direction: column;
}

.sideBtns button {
    width: 100%;
    height: 60px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-align: left;
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
}

.sideBtns button.active {
    font-weight: bold;
    background-color: #F5F5F5;
}

.logoutBtn {
    height: 60px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-align: left;
    display: grid;
    grid-template-columns: 75px auto;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    text-transform: uppercase;
}

@media screen and (max-width: 430px) {
    .dashContainer {
        flex-direction: column;
    }

    .Nav {
        position: absolute;
        height: 100px;
        width: 100vw;
        bottom: 0;
        z-index: 3;
    }

    .sideBtns {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
    }

    .sideBtns button {
        display: flex;
        flex-direction: column;
    }

    .logoutBtn {
        display: none;
    }

    .logoDash {
        display: none;
    }

    .tabs{
        width: 100%;
        height: 87%;
        padding: 0px;
        position: absolute;
        top: 0;
    }

    .radial {
        display: none;
    }

    .dots {
        display: none;
    }

    .mainVideo {
        width: 100%;

    }
}