body {
    overflow: hidden;
}

.homePage {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.homePage::-webkit-scrollbar {
    display: none;
}

.radial {
    background: linear-gradient(135deg, rgba(238,181,198,1) 0%, rgba(193,174,223,1) 70%, rgba(160,188,228,1) 100%);
    width: 530px;
    height: 530px;
    border-radius: 50%;
    position: absolute;
    bottom: 15%;
    right: 15%;
    transform: translate(50%, 50%);
    z-index: 1;
}

.dots {
    --dot-color: white;
    --dot-size: 3px;
    --dot-space: 17px;
    background:
        radial-gradient(circle, var(--dot-color) var(--dot-size), transparent 0) center / var(--dot-space) var(--dot-space);

    width: 300px;
    height: 150px;
    position: absolute;
    bottom: 15%;
    right: 0;
    z-index: 1;
}

.favourites {
    text-align: left;
    position: relative;
    z-index: 2;
}

.recentRow {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    z-index: 1;
    position: relative;
}

.exerciseCard {
    background: linear-gradient(90deg, rgba(238,181,198,1) 0%, rgba(193,174,223,1) 70%, rgba(160,188,228,1) 100%);
    width: 250px;
    height: 150px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.exerciseCard .cardText {
    z-index: 2;
    position: relative;
    color: white;
}

.cardText h3 {
    margin: 0;
    font-size: 24px;
}

.cardText p {
    color: #2D2D2D;
}

.exerciseCard h4 {
    position: absolute;
    z-index: 1;
    color: white;
    opacity: 0.1;
    font-size: 80px;
    top: 0;
    transform: translateY(-50%);
}

.mainVideo {
    width: 70%;
    height: 20%;
    max-height: 500px;
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-rows: 100px auto;
}

.mainVideo video {
    width: 850px;
    height: 400px;
    position: relative;
    z-index: 2;
    border-radius: 8px;
}

.mainVideo .videoContainer {
    position: relative;
    text-align: left;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mainVideo h2 {
    color: black;
    padding: 20px;
    margin: 0;
    font-size: 36px;
}

.mainVideo p {
    color: #666;
}

@media screen and (max-width: 430px) {
    .mainVideo video {
        width: 95vw;
    }

    .exerciseCard h4 {
        font-size: 70px;
    }

    .homePage {
        height: 87vh;
        position: relative;
        top: 0;
    }

    .homePageContainer {
        margin-inline: 10px;
    }

    .mainVideo .videoContainer p {
        display: none;
    }
}

