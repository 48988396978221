.searchPageContainer {
    padding: 20px;
    height: 95%;
    overflow: hidden;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.exerciseList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;
    position: relative;
}

.exerciseList::-webkit-scrollbar {
    display: none;
}

.exerciseCard2 {
    width: 90%;
    min-width: 300px;
    height: 50px;
    margin-top: 5px;
    text-align: left;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #242424;
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-columns: 80% auto auto;
    align-items: center;
}

.addToPlaylist {
    position: relative;
    z-index: 1;
    border: none;
    background: none;
    cursor: pointer;
    color: white;
}

.playlistDropdown {
    position: absolute;
    background-color: #424242;
    border-radius: 2px;
    padding: 10px;
    z-index: 3;
    right: 0;
    top: 50%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.playlistDropdown button {
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    color: white;
}

.playlistDropdown button:hover {
    background-color: #646464;
}

.closeAddToPlaylist {
    color: white;
    position: absolute;
    top: 0%;
    text-align: right;
}

.cardText2 {
    position: relative;
    z-index: 2;
}

.cardText2 h3 {
    margin: 0 0 0px;
    color: white;
    z-index: 2;
}

.cardText2 p {
    margin: 0;
    color: #666;
}

@media screen and (max-width: 430px) {
    input[type="text"] {
        width: 90%;
    }

    .exerciseCard2 {
        width: 90%;
        grid-template-columns: 70% auto auto;
        height: 85px;
    }
}