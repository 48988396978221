
@font-face {
    font-family: 'ABeeZee';
    src:
         url('../Fonts/ABeeZee-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.exerciseContainer {
    text-align: left;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
}

.exerciseContainer::-webkit-scrollbar {
    display: none;
}

.exerciseText {
    position: relative;
    z-index: 2;
    margin-left: 50px;
}

.exerciseText h2 {
    color: #DAA7DB;
    font-family: 'ABeeZee';
    font-weight: 400;
    text-transform: uppercase;
}

.exerciseVideoContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.exerciseVideoContainer video {
    width: 100%;
    height: 600px;
    border-radius: 8px;
}



.relatedVideos h2 {
    color: #DAA7DB;
    font-family: 'ABeeZee';
    font-weight: 400;
    text-transform: uppercase;
}

.controls {
    text-align: right;
}

.controls button {
    border: none;
    background: none;
    cursor: pointer;
}

.exerciseCard2 h3 {
    color: white;
}

@media screen and (max-width: 430px) {
    .exerciseContainer {
        display: flex; 
        flex-direction: column;
        height: 100%;
    }
}