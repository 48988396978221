
.subPage {
    width: 100vw;
    height: 100vh;
    background-color: #EFEFEF;
}

.logoSub {
    font-family: 'Jura';
    text-transform: uppercase;
    font-size: 36px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.subscriptionOptions {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.subscriptionCard {
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 500px;
    background-color: white;
    width: 300px;
}

.subscriptionCard:nth-child(2) {
    background: linear-gradient(90deg, rgba(238,181,198,1) 0%, rgba(193,174,223,1) 70%, rgba(160,188,228,1) 100%);
    color: white;
    height: 550px;
}

.subscriptionCard button {
    border: none;
    color: white;
    background-color: rgba(160,188,228,1);
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}

.subscriptionCard h4, .subscriptionCard ul {
    text-align: left;
}

.logout {
    cursor: pointer;
    position: absolute;
    right: 2%;
    top: 2%;
    border: none;
    background: none;
    font-size: 16px;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 100px auto;
}

.referralSection {
    width: 50%;
    min-width: 300px;
    height: 50px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    position: absolute;
    bottom: 5%;
    left: 60%;
    transform: translateX(-50%);
    text-align: center;
}

.referralSection input {
    max-width: 400px;
    padding: 0;
    padding-inline: 10px;
    height: 100%;
}

.referralSection button {
    border: none;
    background-color: rgba(160,188,228,1);
    color: white;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.errorText {
    color: red;
}

@media screen and (max-width: 430px) {
    .subPage {
        overflow-y: auto;
    }

    .subscriptionOptions {
        flex-direction: column;
        width: 90%;
        row-gap: 20px;
        height: 80%;
    }

    .subscriptionCard, .subscriptionCard:nth-child(2) {
        width: 90%;
        height: 200px;
    }

    .subscriptionCard ul, .subscriptionCard h4 {
        display: none;
    }

    .referralSection {
        bottom: 2%;
        left: 5%;
        transform: translateX(0);
        width: 90%;
    }

    .errorText {
        margin: 5px;
    }
}