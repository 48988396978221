.favouritesPageContainer {
    text-align: left;
}

.favouritesList {
    width: 60%;
}

.exerciseCard4 {
    display: grid;
    grid-template-columns: 90% auto;
    margin-bottom: 5px;
    min-width: 300px;
    height: 50px;
    text-align: left;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #242424;
    cursor: pointer;
    position: relative;
    align-items: center;
}

.exerciseCard4 svg {
    position: relative;
    right: 0;
    top: 0;
}

@media screen and (max-width: 430px) {
    .favouritesList {
        width: 90%;
    }
    
    .exerciseCard4 {
        width: 100%;
        height: 75px;
        grid-template-columns: 85% auto;
        align-items: center;
    }
}