
.playlistContainer {
    text-align: left;
}

.playlistHeader {
    display: grid;
    grid-template-columns: 90% auto;
}

.createPlaylistButton {
    position: relative;
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
}

.playlistContentContainer .dots {
    width: 100%;
    top: 0;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    --dot-color: rgba(238,181,198,1);
    --dot-size: 3px;
    --dot-space: 17px;
    background:
        radial-gradient(circle, var(--dot-color) var(--dot-size), transparent 0) center / var(--dot-space) var(--dot-space);

}

.playlistCard {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    border-radius: 8px;
    background-color: #242424;
    color: white;
    padding-inline: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.modalBackdrop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: linear-gradient(275deg, rgba(238,181,198,1) 0%, rgba(193,174,223,1) 70%, rgba(160,188,228,1) 100%);
    padding: 20px;
    z-index: 2;
}

.modalContent {
    width: 100%;
    height: 100%;
}

.modalContent input {
    width: 100%;
    padding: 0;
    padding-block: 10px;
}

.modalContent button {
    border: none;
    background-color: white;
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
}

.playlistContentContainer {
    width: 90%;
    height: 90%;
    background-color: #121212;
    border-radius: 8px;
    color: white;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 2%;
}

.toggleSearch {
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    font-weight: 100;
    font-size: 20px;
}

.playlistContent {
    position: relative;
    z-index: 2;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
}

.playlistContent button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.playlistContent h2 {
    align-items: center;
    display: flex;
}

.playlistContent h2 button {
    margin-left: 20px;
}

.playlistContent ul, .searchContainer ul {
    list-style: none;
    margin-inline: 0;
    padding-inline: 0;
}

.playlistContainer li, .searchContainer li {
    height: 20px;
    padding: 5px;

}

.videoContainer {
    height: 80%;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    color: black;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 40px;
    z-index: 3;
}

.videoContainer video {
    width: 90%;
    height: auto;
}

.nextExercise {
    position: absolute;
    bottom: 5%;
    right: 5%;
    text-transform: uppercase;
    border: none;
    padding: 10px;
    cursor: pointer;
    min-width: 100px;
}

.videoContainer .nextExercise {
    background-color: rgba(160,188,228,1);
    color: white;
}

.mainVidContent {
    width: 100%;
}

.exitPlaylistVideo {
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    background: none;
    color: black;
    width: 50px;
    border: none;
    cursor: pointer;
    min-width: 50px;
}

.controlsContainer {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 50px 50px;
}

.controlsContainer button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 24px;
}

.searchContainer {
    position: absolute;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: black;
    width: 50%;
    min-width: 600px;
}

.searchContainer input {
    width: 70%;
}

.searchResults {
    overflow-x: hidden;
    overflow-y: auto;
    height: 200px;
}

.searchResults li {
    display: grid;
    grid-template-columns: 40% 50px;
}

.searchResults li button {
    border: none;
    background: none;
    color: black;
    cursor: pointer;
    font-size: 18px;
}

.closeSearch {
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 18px;
}

.addIcon {
    background-color: #363636;
    width: 50px;
    height: 50px;
    border-radius: 2px;
}

.addIcon svg {
    width: 90%;
    height: 90%;
}

.exerciseInPlaylist {
    display: grid;
    grid-template-columns: 80% auto;
    margin-bottom: 10px;
}

.inPlaylistDetails {
    display: flex;
    flex-direction: column;
}

.inPlaylistDetails h3, .inPlaylistDetails p {
    margin: 2px;
}

.inPlaylistDetails p {
    color: #646464;
}

.exerciseCount {
    color: #646464;
}

.tagContainer ul {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    flex-wrap:wrap;
}

.tagContainer ul li {
    background-color: #484848;
    border-radius: 50px;
    padding-inline: 20px;
    margin-top: 10px;
}

@media screen and (max-width: 430px) {
    .playlistContainer {
        padding: 20px;
    }

    .playlistContentContainer {
        left: 0;
    }

    .playlistContentContainer .dots {
        display: block;
    }

    .playlistContentContainer .videoContainer {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .nextExercise {
        bottom: 93%;
    }

    .exitPlaylistVideo {
        left: 5%;
    }
}