
@font-face {
    font-family: 'Jura';
    src:
         url('../Fonts/JuraBook.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.fileTransferContainer h2 {
    text-align: left;
}

.bodyContainer {
    background: linear-gradient(90deg, rgba(238,181,198,1) 0%, rgba(193,174,223,1) 70%, rgba(160,188,228,1) 100%);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.loginContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 630px;
    height: 70%;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
}

.logo {
    font-family: 'Jura';
    font-weight: bold;
    text-transform: uppercase;
    font-size: 36px;
}

    .loginContainer img {
        width: 200px;
        position: absolute;
        padding: 20px;
        left: 0;
    }

.forgotPasswordContainer {
    text-align: left;
}

.signUp, .login, .forgotPasswordContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

    .signUp h2, .login h2 {
        text-align: left;
        padding-bottom: 10px;
    }

form {
    display: flex;
    flex-direction: column;
    width: 350px;
    row-gap: 20px;
}

    form input {
        border: none;
        height: 20px;
        background-color: transparent;
        border-bottom: 2px solid #D2D7E0;
        color: black;
        padding-block: 10px;
    }

.buttonContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 100%;
    padding-top: 50px;
}

form button:nth-child(1) {
    background-color: transparent;
    color: black;
    border-bottom: 4px solid black;
}

form button {
    background-color: #DAA7DB;
    color: white;
    border: none;
    height: 50px;
    font-weight: bold;
    width: 50%;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
}

    form button[type="submit"] {
        background-color: rgba(160,188,228,1);
    }

.forgotPassword {
    background-color: transparent;
    color: black;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    height: 20px;
    padding-bottom: 10px;
}

.backBtnLogin {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 20%;
    padding: 35px;
    font-size: 18px;
    align-items: center;
}

    .backBtnLogin svg {
        position: relative;
        top: 5px;
    }

.termsCheckBox {
    display: grid;
    grid-template-columns: 25px auto;
}

.serviceOptions {
}

    .serviceOptions button {
        background: none;
        border: none;
        cursor: pointer;
    }

.optionIcon {
    background-color: #EAEAEA;
    width: 120px;
    height: 120px;
    border-radius: 8px;
    flex-shrink: 0;
    margin-bottom: 20px;
}

    .optionIcon svg {
        width: 100px;
        height: 100px;
        fill: black;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

.messageContainer {
    position: absolute;
    z-index: 6;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
